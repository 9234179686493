import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Banner from "../components/banner"
import Foreword from "../components/images/drheri.png"
import Poster from "../components/images/poster.jpg"

const AboutPage = () => (
  <Layout>
    <SEO title="About" />
    <Banner title="About"></Banner>

    <section id="content">
      <div className="content-wrap">
        <div className="container clearfix">
          <div className="col-md-12 col-lg-8">
            <div className="row clearfix">
              <div className="mb-0">
                <div className="heading-block" style={{ paddingTop: "40px" }}>
                  <h3>Foreword</h3>
                  <img
                    src={Foreword}
                    className="bor-tr mt-3 mb-3"
                    alt="FOREWORD"
                  />
                  <p>
                    Medicine is the science and practice of the diagnosis,
                    treatment, and prevention of disease. Medicine encompasses a
                    variety of health care practices evolved to maintain and
                    restore health by the prevention and treatment of illness.
                    Contemporary medicine applies biomedical sciences,
                    biomedical research, genetics, and medical technology to
                    diagnose, treat, and prevent injury and disease, typically
                    through pharmaceuticals or surgery but also through
                    therapies. Besides, health science accommodates any
                    circumstances around medicine.
                  </p>
                  <p>
                    Medicine has existed for thousands of years, during most of
                    which it was an art (an area of skill and knowledge)
                    frequently having connections to the religious and
                    philosophical beliefs of local culture. Medical availability
                    and clinical practice varies across the world due to
                    regional differences in culture and technology. Modern
                    scientific medicine is highly developed in the Western
                    world, while in developing countries such as parts of Africa
                    or Asia, the population may rely more heavily on traditional
                    medicine. Traditional medicine can be typical for developing
                    country such as Indonesia, but also can be strength if it is
                    developed in the right path.
                  </p>
                  <p>
                    Since knowledge, techniques, and medical technology continue
                    to evolve at a rapid rate, many regulatory authorities
                    require continuing medical education. Medical practitioners
                    upgrade their knowledge in various ways, including medical
                    journals, seminars, conferences, and online programs.
                    Medicine and health science development requires
                    interdisciplinary and takes place in a global setting.
                    Universitas Airlangga as one of the leading universities in
                    Indonesia in its effort to become a world class university
                    seeks to promote the science of health globally.
                  </p>
                  <p>
                    This conference and workshop will accommodate broad topics
                    in medical research and health sciences. Conferences and
                    workshops will discuss the basics of stem cells, tissue
                    engineering and around the field of regenerative medicine
                    and how to write and publish good papers. Conference on
                    seminar materials by world experts and hands on workshops
                    with experts as trainers and facilitators. Furthermore, this
                    training is designed to facilitate scientists and
                    professionals to share and discuss various studies, in an
                    international atmosphere with oral or poster displays. We
                    also encourage students to present the results of their
                    research.
                  </p>
                  <h4 className="mb-0">Heri Suroto, MD., PhD.</h4>
                  <h4>Chairman</h4>
                </div>
              </div>
            </div>
            <div className="row clearfix">
              <div className="mb-0">
                <h3>About Events</h3>
                <p>
                  The International Conference of Asia Pacific Association of
                  Surgical Tissue Banks (APATSB 2021) is a bi-annual scientific
                  meeting. In its 18th event, this scientific meeting has
                  transformed into virtual event that will bring together
                  leaders, scientists, clinicians, business leaders, ethicists,
                  and educators dedicated to the responsible advancement of stem
                  cell research from across the asia pacific to connect,
                  collaborate, and discuss the latest advances in the the field,
                  new data, and the year's most compelling breakthroughs - all
                  from a safe, physical distance.
                </p>
                <p>
                  While it may look a little different, the APASTB 2021 Virtual
                  meeting's exceptional scientific programming and networking
                  opportunities will continue to provide inspiration and insight
                  to advance this dynamic field of research :
                </p>
                <ul className="ol-size ml-4 mb-2">
                  <li>
                    Learn from the world's scientific leaders who will share
                    their cutting edge research.
                  </li>
                  <li>
                    Access to ALL conference seminars, workshops, and special
                    networking events!
                  </li>
                  <li>
                    Unprecedented access to ALL scientific sessions and events
                    live and on-demand through the duration of the meeting.
                    Watch and engage when you like!
                  </li>
                  <li>
                    Opportunities to connect with the scientific community
                    through digital hubs around scientific topics, sessions, and
                    career stages. Keep the conversation and networking going!
                  </li>
                  <li>
                    Immerse yourself in the largest "virtual" exhibit hall
                    solely dedicated to stem cell research and advancement - and
                    see new tools and technologies that will help you advance
                    research. Latest tools and tech!
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="col-md-12">
            <div className="row clearfix">
              <div className="mb-0">
                <div className="heading-block" style={{ paddingTop: "40px" }}>
                  <img src={Poster} className="bor-tr mt-3 mb-3" alt="POSTER" />
                </div>
              </div>
            </div>
          </div>

          <div className="divider divider-center mb-4 mt-4" />

          <div className="col-md-12 col-lg-8">
            <div className="row clearfix">
              <div className="mb-0 mt-6">
                <p>See APASTB Previous events :</p>
                <a
                  href="http://www.apastb.org/program.php?selTab=0"
                  className="mb-3 mt-3"
                  target="blank"
                >
                  <h3 className="color-apastd">
                    2018 (Selangor, Malaysia) <i className="icon-arrow-right" />
                  </h3>
                </a>
                <a
                  href="http://www.apastb.org/program.php?selTab=1"
                  className="mb-3 mt-3"
                  target="blank"
                >
                  <h3 className="color-apastd">
                    2016 (Seoul, Korea) <i className="icon-arrow-right" />
                  </h3>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </Layout>
)

export default AboutPage
